.PdfPage  {
    width:75%;
    height: auto;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.logos{
    width: 100%;
    height: 20%;
    /* background-color: coral; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.pdfFile{
    width: 100%;
    height: 80%;
    /* background-color:crimson; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}


.ADEK{
    width: 40%;
    max-height: 60%;
}
.mubadala{
    width: 40%;
    max-height: 60%;
}


.pdfcontainer{
    width: 90%;
    height: 100%;
    margin-top: 10%;
    /* background-color: rgb(255, 0, 242); */
    flex-direction: column;
    display:flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;

}
.thefile{
    /* background-color: aqua; */
    
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    filter: drop-shadow(12px 12px 23px rgba(0, 0, 0, 0.25));
}
/* .react-pdf__Page{
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    position: relative;
}
.react-pdf__Page__canvas{
    height: 100%!important;
    width: 70% !important;
    filter: drop-shadow(12px 12px 23px rgba(0, 0, 0, 0.25));
} */


.warning{
    width: 55%;
}

.warningParagraph{
    width: 65%;
    color: #E24C4B;
    font-size: 3.7vw;
}
.warningParagraph span{
    font-weight: bold;
}

@media only screen and (max-width: 768px){
    .PdfPage{
        width: 100%;
    }
    .thefile{
        filter: drop-shadow(0px 12px 23px rgba(0, 0, 0, 0.25));

    }
    
}