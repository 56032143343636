.mobile_top{
    display: none;
}

@media only screen and (max-width: 768px){
    .mobile_top{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        background-color: #E8EAEB;
        box-shadow: 0px 12px 23px rgba(0, 0, 0, 0.25);
        margin-bottom: 50px;
    }
    .decenture_logo{
        width: 90%;
        margin-bottom: 10px;
    }
    .name{
        width: 60%;
        height: 10vw;
        background-color: #a6a9ad;
        border-radius: 1vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5%;
    }

    .nameCercle{
        width: 9vw;
        height: 9vw;
        border-radius: 5vw;
        background-color: #E2A939;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2vw;
    }
    .nameFirstLetter{
        font-size: 6vw;
        font-weight:bolder;
    }
    .fullName{
        font-size: 4vw;
        font-weight: bold;
        margin-right: 15%;
    }
    .title{
        font-size: 3vw;
        margin-bottom: 20px;
        height: 3vw;
    }
}