.mobile_bottom{
    display: none;
}
@media only screen and (max-width: 768px){
    .mobile_bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;
        margin-top: 40px;
    }
    .programName{
        font-size: 5vw;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .programDiscription{
        font-size: 5vw;
        width: 80%;
    }
    .line{
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .date{
        font-size: 5vw;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .verified{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .verified p{
        font-size: 5vw;
        height: 6vw;
    }
    .verified img{
        height: 5vw;
        margin-left: 10px;
    }
    .buttons{
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 3vw;
    }
    
    .button{
        width: 12vw;
        height: 12vw;
        background: #a6a9ad;
        border: 2px solid #7e7f80;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 1vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .button:hover{
        background: #7e7f80;
    }
}