.Sidebar{
    width: 25%;
    height: auto;
    background-color: brown;
    position: relative;

}


.SidebarContent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #E8EAEB;
    box-shadow: 12px 12px 23px rgba(0, 0, 0, 0.25);

}
.SidebarContent.notVerified{
    justify-content:space-between;
    height: 100%;
}
.buttom{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.decentureLogo{
    width: 90%;
    margin-bottom: 0.8vw;
}
.line{
    width: 70%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.3);
    margin-bottom: 1.3vw;
    margin-top: 1.3vw;
}
.title{
    font-size: 1vw;
    margin-bottom: 1vw;
    height: 1vw;
}

.name{
    width: 80%;
    height: 4.43vw;
    background-color: #a6a9ad;
    border-radius: 0.4vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nameCercle{
    width: 3.1vw;
    height: 3.1vw;
    border-radius: 1.9vw;
    background-color: #E2A939;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.6vw;
}
.nameFirstLetter{
    font-size: 1.5vw;
    font-weight:bolder;
}
.fullName{
    font-size: 1.25vw;
    font-weight: bold;
    margin-right: 15%;
}

.programName{
    font-size: 1.6vw;
    font-weight: bold;
    margin-bottom: 0.6vw;
}

.programDiscription{
    font-size: 1.6vw;
    width: 80%;
}

.date{
    font-size: 1vw;
    font-weight: bold;
    margin-bottom: 0.6vw;
}

.buttons{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1.6vw;
}

.button{
    width: 3.7vw;
    height: 3.7vw;
    background: #a6a9ad;
    border: 3px solid #7e7f80;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button:hover{
    background: #7e7f80;
}

.button img{
    height: 80%;
}
.qrcode{
    width: 90%;
    margin-bottom: 3%;
}

.verified{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.verified p{
    font-size: 1vw;
    height: 1.5vw;
}
.verified img{
    height: 25px;
    margin-left: 5px;
}

.qrImg{
    width: 80% !important;
    height: auto !important;
}
@media only screen and (max-width: 768px){
    .Sidebar{
        display: none;
    }
}
