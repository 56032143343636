.share{
    width: 100vw;
    height: 100vh;
    
    background-color: rgba(31, 23, 23,0.5);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 100;
    /* position: relative; */
}

.share h1{
    position: fixed;
    top:10px;
    right: 10px;
    color: rgb(255, 255, 255);
    cursor: pointer;

}
.shareList{
    width: 70%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: whitesmoke;
}
.shareButton{
    margin: 3%;
    height: auto;
}
