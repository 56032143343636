.Page{
    display: flex;
    flex-direction: row;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 3%;
    margin-bottom: 2%;
    width: 100%;
    height: 100%;
    /* background-color: chartreuse; */
    position: relative;
}
@media only screen and (max-width: 768px){
    .Page{
        flex-direction: column;
        margin-top: 0px;
    }
}